<template>
  <!-- This will act as a layout wrapper for child routes -->
  <TsHeader data-testid="header" />

<!--  useCapitalize(brandStore.brandSlug.replaceAll('-', ' '))-->

  <div
      class="min-h-screen h-full bg-[linear-gradient(143.08deg,#F7FBFF_21.45%,#EEF7FF_78.55%)]"
  >
    <TsSection
        :append-class="twMerge('overflow-hidden mb-4', isMobile && 'px-0')"
    >
      <TsBreadcrumb :data="breadcrumbData" />
      <TsTitle
          animate
          :title="'Dewalt'"
          append-class="before:bg-dewalt mt-4"
      />
    </TsSection>

    <TsSection :container-class="isMobile && 'px-0'">
      <NuxtImg
          class="w-full mb-4"
          :src="
          isMobile
            ? '/banners/brands-detail-banner-mobile.png'
            : '/banners/brands-details-banner-desktop.png'
        "
      />
    </TsSection>

    <TsSection>
      <TsRow append-class="m-0">
        <!-- Rendering Tabs -->
        <TsRow append-class="m-0">
          <TsTabsCategorySelection
              @tab-click="handleTabClick"
              :tabs="tabs"
              :active="brandStore.activeTab"
              :append-class="isMobile ? 'p-0 pb-4' : 'p-0 py-3 ml-0 mt-0'"
          />
        </TsRow>

        <!-- Using NuxtChild to render the correct component based on the route -->
      </TsRow>
    </TsSection>

    <div>
      <slot />
    </div>
  </div>





  <TsProductActions
    v-if="config.public.featureFlags.enableCTAAddToTrolleyBuyNow"
  />

  <TsProductActionsv2
    v-else-if="config.public.featureFlags.enableCTACollectionDelivery"
  />
  <TsFooter data-testid="footer" />
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
const { isMobile } = useDevice();
const runtimeConfig = useRuntimeConfig();
const brandStore = useBrandStore();
const config = useRuntimeConfig();

const breadcrumbData = ref([
  { text: "Home", links: "/" },
  { text: "Brand", link: "/brands" },
]);

const tabs = [
    {
      id:'about',
      label:'About'
    },
    {
      id:'products',
      label:'Products'
    },
    {
      id:'deals',
      label:'Deals'
    }
  ];

const route = useRoute();


onMounted(async () => {
});

const handleTabClick = (tab) => {
  // Get the brand name from the route
  const brandName = brandStore.brandSlug;

  let routePath = `/brands/${brandName}`;

  if (tab.label.includes("Products")) {
    routePath += "/products";
  } else if (tab.label.includes("Deals")) {
    routePath += "/deals";
  }

  navigateTo(routePath);
  // Update the URL without reloading the page
};


</script>
